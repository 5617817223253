import React from 'react'

function Pdf() {
  return (
    <>
    <div className='mobileHide' style={{display:"flex", justifyContent: "center", width: "100%"}}>
      <iframe src="https://d3nqrugly3oele.cloudfront.net/CTL%20Newsletter.pdf#toolbar=0" width="100%" height="1200"></iframe>
    </div>
        <div className='mobileShow'>
This browser does not support PDFs. Please download the PDF to view it:  
<a href="https://d3nqrugly3oele.cloudfront.net/CTL%20Newsletter.pdf#toolbar=0" target="_blank" download>Download PDF</a>
    </div>
    </>
  )
}

export default Pdf